import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

// Disable right-click
document.addEventListener("contextmenu", (e) => e.preventDefault());

// Disable text selection
document.addEventListener("selectstart", (e) => e.preventDefault());

// Disable keyboard shortcuts for developer tools
document.addEventListener("keydown", (e) => {
  // Disable F12
  if (e.key === "F12") {
    e.preventDefault();
  }

  // Disable Ctrl+Shift+I (Chrome DevTools)
  if (e.ctrlKey && e.shiftKey && e.key === "I") {
    e.preventDefault();
  }

  // Disable Ctrl+Shift+J (Chrome DevTools Console)
  if (e.ctrlKey && e.shiftKey && e.key === "J") {
    e.preventDefault();
  }

  // Disable Ctrl+Shift+C (Chrome DevTools Elements)
  if (e.ctrlKey && e.shiftKey && e.key === "C") {
    e.preventDefault();
  }

  // Disable Command+Option+I (Mac DevTools)
  if (e.metaKey && e.altKey && e.key === "i") {
    e.preventDefault();
  }
});

// Override and disable console methods
const disableConsole = () => {
  const noop = () => {};
  const methods = ["log", "debug", "info", "warn", "error", "table", "trace"];

  methods.forEach((method) => {
    console[method] = noop;
  });
};

// Call the function to disable console
disableConsole();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
