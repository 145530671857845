import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import "./App.scss";

// mobile
const isMobile =
  /mobile/i.test(navigator.userAgent) || window.screen.width < 1100;

function App() {
  const mediaList = [
    {
      icon: require("./assets/img/home3/twitter-x.png"),
      url: "https://x.com/pieverse_io",
    },
    {
      icon: require("./assets/img/home3/telegram.png"),
      url: "https://t.me/pieverse_news",
    },
    // {
    //   icon: require("./assets/img/Header_Facebook.png"),
    //   url: "",
    // },
    // {
    //   icon: require("./assets/img/Header_Instagram.png"),
    //   url: "",
    // },
    // {
    //   icon: require("./assets/img/Medium.png"),
    //   url: "https://medium.com/@pieverse",
    // },
  ];

  const potsList = [
    require("./assets/img/home3/pot-card-1.png"),
    require("./assets/img/home3/pot-card-2.png"),
    require("./assets/img/home3/pot-card-3.png"),
    require("./assets/img/home3/pot-card-4.png"),
  ];

  return (
    <div className="app">
      {isMobile ? (<>
        <section className="mobile_app-header">
          <img src={require("./assets/img/home3/mobile_Header.webp")} alt=""/>
        </section>
        <section>
          <div className="mobile_app-media">
            {mediaList.map((item, index) => (
              <a
                key={index}
                href={item.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={item.icon} alt="" />
              </a>
            ))}
          </div>
        </section>
        <section className="mobile_app-passion">
          <img src={require("./assets/img/home3/mobile_passion.webp")} alt=""/>
        </section>
        <section className="mobile_app-marketplace">
          <img src={require("./assets/img/home3/mobile_Characters.webp")} alt=""/>
        </section>
        <section className="mobile_app-staytuned">
          <img src={require("./assets/img/home3/mobile_staytuned.webp")} alt=""/>
        </section>
        <section className="mobile_app-footer">
          <img src={require("./assets/img/home3/mobile_Footer.webp")} alt=""/>
        </section>
      </>) : (<>
        <section className="app-header">
          <img src={require("./assets/img/home3/Header.webp")} alt=""/>
          <div className="app-media">
              {mediaList.map((item, index) => (
                <a
                  key={index}
                  href={item.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={item.icon} alt="" />
                </a>
              ))}
            </div>
        </section>
        <section className="app-gallery">
          <img src={require("./assets/img/home3/Gallery.webp")} alt=""/>
        </section>
        <section className="app-footer">
          <img src={require("./assets/img/home3/Footer.webp")} alt=""/>
        </section>
      </>)}
    </div>
  );
}

export default App;
